<template>
  <div>
    <van-overlay :show="loading">
      <van-loading size="24px" class="information-van-loading">
        <span>{{ '加载中···' }}</span>
      </van-loading>
    </van-overlay>
    <information-page button-title="返回" :page-title="pageTitle" :less-title="lessTitle" :van-icon="vanIcon" :path-name="pathName" :icon-class="iconClass" :query="query" />
  </div>
</template>
<script>
import InformationPage from '@/components/information-page'
import { Loading, Overlay } from 'vant'
export default {
  components: {
    InformationPage,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay
  },
  data () {
    return {
      BizToken: this.$route.query.BizToken,
      loading: false,
      pageTitle: '',
      lessTitle: '',
      vanIcon: '',
      pathName: '',
      iconClass: '',
      query: {}
    }
  },
  created () {
    this.loading = true
    this.utils.faceDetectAuth(this.BizToken).then(result => {
      this.loading = false
      this.pageTitle = '恭喜您，身份认证通过！'
      this.lessTitle = '请等待平台审核！'
      this.vanIcon = 'checked'
      this.pathName = 'login'
      this.iconClass = 'icon-class'
    }, () => {
      this.loading = false
      this.pageTitle = '身份认证失败，请重新认证'
      this.lessTitle = ''
      this.vanIcon = 'clear'
      this.pathName = 'personalInformationAdministrators'
      this.iconClass = ''
      this.query = { enterpriseId: this._.get(this.$store.state, 'employeeInfo.enterpriseId', '') }
    })
  },
  methods: {
  }
}
</script>

<style>

</style>
